import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Presenta un capell de fins a 3 cm de diàmetre, semihemisfèric, de color groc palla, lluent i viscós en temps humit. El peu, que és molt llarg, de 5 a 10 cm x 2 a 5 mm, és cilíndric, amb base bulbosa, aviat buit, fibrós i viscós, és de color més pàl·lid que el capell. Generalment s’observen les restes de l’anell aferrades al peu i tacades de negre per les espores. Aquestes són violàcies en massa, el·líptiques, llises, de 15-22 x 8-10 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      